<template>
  <Box>
    
    <ErrorRow :error="error" />
    <SuccessRow :message="message" />
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    
    <VehicleMenuRow 
      :vehicleData="vehicle.data" 
      :showBorder="true" 
      :isAdmin="isAdmin" 
      v-on:action="action" 
      selected="inspections"/>

    <TitleRow>
      Vehicle Inspection Reports for {{ decode(vehicle.name()) }} ({{ vehicle.plate() }})
    </TitleRow>
      
    <Row>
      <Column>
        <InspectionList :vehicle="vehicle.data" :start="startComponent" />
      </Column>
    </Row>

    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    <SpacerRow v-else />

  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

//import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import InspectionList from '@/views/portal/inspections/list/InspectionList.vue'

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import VehicleMenuRow   from '@/portals/customer/operator/views/vehicle/menu/Menu.vue';
import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: 'portal-customer-operator-vehicle-inspections',
  components: {
    ErrorRow,
    SuccessRow,
    SpacerRow,
    Box, Row, Column,
    TitleRow, LoadingRow,
    InspectionList,
    VehicleMenuRow,
    SessionTimeoutDialog,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      id: this.$route.params["id"],
      isLoading: false,
      loadingMessage: "",
      showSessionTimeoutDialog: false,
      
      MC: new MC(),
      
      total: 0,
      page: 0,
      pageSize: 10,
      pageNumber: 0,
      startComponent: false,
      stateFilter: 'all',
      schedules: [],
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
      //
    ]),
    company: function () {
      return this.domain.session().company();
    },
    vehicle: function() {
      if (this.domain) {
        return this.domain.vehicles().findById(this.id);
      }
      return new Vehicle(this.domain);
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.message = null;
      this.loadingMessage = "";
      this.startComponent = true;
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    
    loadData: function() {
      this.loadDataListener();
    },
    
    loadDataListener: function(response) {
      if (response) {
        //
      }
      this.isLoading = false;
    },
    
    refresh: function () {
      this.schedules = [];
      this.loadData();
    },

    action: function(name) {
      if (name === "refresh") {
        this.refresh();
      }
    },
  },
  
};
</script>
